import React from 'react'
import striptags from 'striptags'
import styled from "styled-components"

const HitCopy = styled.div `
    grid-column: 5/12;
`

const Highlight = ({ source, query, tag = 'span' }) => {
  const regex = new RegExp(query.trim(), 'gi')

  const toRender = unescape(striptags(source))
    .replace(regex, `<${tag}>$&</${tag}>`)

  return (
    <HitCopy className="body-copy-small" dangerouslySetInnerHTML={
      { __html: toRender }
    }></HitCopy>
  )
}

export default Highlight
