import React, { Component } from "react";
import styled from "styled-components"
import Img from 'gatsby-image'
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql, Link } from 'gatsby'
import Search from '../components/Header/Search';
import Layout from "../components/layout"
import Close from "../components/Header/close";
import PrintUnavailable from "../components/no-print.js";

import Header from '../components/Header';
import Footer from '../components/footer';
import media from "styled-media-query";



const CloseBtn = styled.div`
    position: fixed;
    top: 40px;
    right: 40px;
    g {
        fill: #fff !important;
    }
    ${media.greaterThan("medium")`
        top: 140px;
        right: 140px;
    `}
`;
const ArticleLink = styled.span`
  margin-top: 10px;
  display: block;
  a {
      text-decoration: underline;
  }
`;
const PrintModal = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0;
    z-index: 9997;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    ${media.greaterThan("medium")`
        padding: 100px;
    `}
`;
const MenuItem = styled.div `
    display: inline-block;
    font-family: am, sans-serif;
    font-size: 14px;
    line-height: 40px;
    font-weight: 400;
    text-transform: uppercase;
`


const DailyFlex = styled.div`
    display: block;
    ${media.greaterThan("medium")`

    min-height: 800px;
    max-width: 1640px;
        width: 100%;
        display: grid;
        grid-template-columns: max-content fit-content(10%);
        gap: 0px 50px;
        margin: 0 auto;
        padding: 20px 50px 0 50px;
        height: calc(100vh - 105px);
        align-items: start;
    `}

`

const OverviewContent = styled.div`
    width: 100%;
    padding: 25px;

    ${media.greaterThan("medium")`
        grid-area: 1 / 2 / 2 / 3;
        width: 440px;
        min-height: 800px;
    `}
`;


const OverviewImg = styled.div`

    max-width: 100%;
    display: block;
    width: 100%;
    height: ${props => props.gutter * 100}%;
    overflow: hidden;

    ${media.greaterThan("medium")`
        width: ${props => props.gutter * 80}vh;
        align-items: center;
        height: 80vh;
        grid-area: 1 / 1 / 2 / 2;
        grid-template-columns: fit-content(10%);
        min-height: 800px;
    `}
`
const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=https://www.over-view.com/overviews/'



class singleOverview extends Component {
    state = {
        unavailable: false,
        hovered: false,
        printName: {}
    };
    setHover = () => this.setState({ hovered: true });
    cancelHover = () => this.setState({ hovered: false });

    openModal = parameter => {
        const { unavailable } = this.state;
        if (!unavailable) {
            this.setState({
                unavailable: true,
                printName: parameter
            });
        }
    };

    closeModal = () => {
        const { unavailable } = this.state;
        if (unavailable) {
            this.setState({ unavailable: false });
        }
    };
    render() {
        const { data } = this.props;
        return (
            <Layout>
                <HelmetDatoCms>
                    <title>{data.datoCmsOverview.title}</title>
                    <meta
                        property="og:title"
                        content={data.datoCmsOverview.title}
                    />
                    <meta
                        property="og:description"
                        content={data.datoCmsOverview.description}
                    />
                    <meta
                        property="og:image"
                        content={data.datoCmsOverview.image.url}
                    />
                </HelmetDatoCms>

                <Header content_color="black">
                    <MenuItem>
                        <Link to="/daily" className="nav-menu-link">
                            Daily
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/digital-index" className="nav-menu-link">
                            Index
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/map" className="nav-menu-link">
                            Map
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Search
                            placeholder="Search"
                            className="global-search search"
                        />
                    </MenuItem>
                </Header>

                <DailyFlex>
                    <OverviewImg
                        gutter={data.datoCmsOverview.image.fluid.aspectRatio}
                    >
                        <Img
                            fluid={data.datoCmsOverview.image.fluid}
                            alt={data.datoCmsOverview.title}
                            durationFadeIn={500}
                            placeholderStyle={
                                {
                                    // "filter": "blur(10px) contrast(1.5) saturate(1.5)",
                                    // "boxShadow": "0 0 100px 200px inset black",
                                }
                            }
                        />
                    </OverviewImg>

                    <OverviewContent>
                        <h1
                            id="w-node-0e4a4f7ac915-5940d611"
                            className="subheader daily-header"
                        >
                            {data.datoCmsOverview.title}
                        </h1>
                        <p className="body-copy-small overview-description">
                            {data.datoCmsOverview.description}

                            {data.datoCmsOverview.articleLink && (
                                <ArticleLink>
                                    <Link
                                        to={`/stories/${data.datoCmsOverview.articleLink.slug}`}
                                    >
                                        Read the full story &rarr;
                                    </Link>
                                </ArticleLink>
                            )}
                        </p>

                        <p className="caption overview-geo">
                            {data.datoCmsOverview.geo.latitude.toFixed(6)}°,{" "}
                            {data.datoCmsOverview.geo.longitude.toFixed(6)}°
                            <br />
                        </p>
                        <p className="caption overview-source">
                            {data.datoCmsOverview.source}
                            <br />
                        </p>
                        <div className="meta-btns">
                            {data.datoCmsOverview.shopLink && (
                                <Link
                                    to={data.datoCmsOverview.shopLink}
                                    className="caption daily-links"
                                >
                                    Shop
                                </Link>
                            )}

                            {!data.datoCmsOverview.shopLink && (
                                <p
                                    onClick={this.openModal.bind(
                                        this,
                                        data.datoCmsOverview.title
                                    )}
                                    className="caption daily-links"
                                >
                                    Shop
                                </p>
                            )}

                            <p className="caption daily-links">
                                <a
                                    href={`mailto:?subject=Check out this post on Overview&body=Check out the ${data.datoCmsOverview.title} post on Overview: https://www.over-view.com/overviews/${data.datoCmsOverview.slug}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Email
                                </a>{" "}
                                |{" "}
                                <a
                                    href={
                                        facebookUrl + data.datoCmsOverview.slug
                                    }
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    FB
                                </a>
                            </p>
                        </div>
                    </OverviewContent>
                </DailyFlex>
                <Footer />
                {this.state.unavailable ? (
                    <PrintModal>
                        <PrintUnavailable name={this.state.printName} />
                        <CloseBtn onClick={this.closeModal}>
                            <Close />
                        </CloseBtn>
                    </PrintModal>
                ) : null}
            </Layout>
        );
    }
}

export default singleOverview;
export const query = graphql`
           query overviewQuery($slug: String!) {
               datoCmsOverview(slug: { eq: $slug }) {
                   title
                   slug
                   description
                   source
                   shopLink
                   articleLink {
                       slug
                   }
                   geo {
                       latitude
                       longitude
                   }
                   image {
                       url
                       fluid(
                           maxWidth: 900
                           imgixParams: {
                               w: "900"
                               fit: "clip"
                               auto: "compress"
                           }
                       ) {
                           ...GatsbyDatoCmsFluid
                       }
                   }
               }
           }
       `;
