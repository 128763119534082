import React from 'react'
import AlgoliaSearch from '../AlgoliaSearch'

const Search = (props) => {
  return (
    <div>
    <AlgoliaSearch
      maxResults={10}
      placeholder={props.placeholder}
      className={props.className}
      indexes={[
        {
          name: "overview",
          label: "Overviews",
          section: "overviews",
          viewAllLabel: "Overview"
        }
      ]}
    />
    </div>
  )
}

export default Search;
