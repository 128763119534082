import React, { Component } from 'react'
import styled from "styled-components"
import { Link } from "gatsby"
import HitComponent from './HitComponent'
import searchClient from './client'

const SearchSection = styled.div `
     
`
const SearchContainer = styled.div `
    display: block;
    max-width: 1640px;
    width: 100%;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 60px;
`

const HitGrid = styled.section `
    display: grid;
    max-width: 1640px;
    width: 100%;
    margin: 0px auto 40px auto;
    grid-column-gap: 50px;
    grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr) minmax(1px, 1fr);
    align-items: start;
    
    h3 {
        font-family: nhp;
        text-transform: none;
        grid-column: 2/12;
        margin: 0;
        padding: 10px 0;
        border-bottom: 2px solid #000;
        color: #000;
        span {
            font-size: 14px;
            opacity: .5;
            margin-left: 20px;
        }
    }
    
    a {
        opacity: .5;
        float: right;
        font-size: 14px;
    }
`

class AlgoliaSearch extends Component {
  state = {
    queryResults: [],
    query: ''
  }

  componentWillMount(){
    const { indexes } = this.props
    this.indexes = indexes
      .map(i => searchClient.initIndex(i.name))
  }

  onTextChange = ev => {
    const { value: query } = ev.target
    if(query.length === 0){
      return this.setState({ queryResults: [], query })
    }
    this.setState({ query })
    Promise.all(this.indexes.map(i => i.search({ query })))
      .then(res => console.log({res}) || this.setState({ queryResults: res }))
  }
  render() {
    const { indexes, maxResults } = this.props
    const { queryResults, query } = this.state
    
    return (
      <>
        <input
          type="text"
          placeholder={this.props.placeholder}
          className={this.props.className}
          onChange={this.onTextChange} />

        {queryResults[0] && <div className={`hits-container hits-${this.props.className}`}>
            
            {indexes.map((index, i) => queryResults[i].hits && queryResults[i].hits.length > 0 &&
                <SearchContainer key={index.name} className={index.label} style={{backgroundImage: "url(" + require("../../images/icons/icon__search.svg") + ")"}}>
                <HitGrid>
                    <h3 className="subheading">{index.label}<Link to={`/search/#query=${query}&index=${index.name}`}> View All ({queryResults[i].nbHits})</Link></h3>
                </HitGrid>
                <SearchSection>{queryResults[i].hits.slice(0, maxResults).map(hit => (
                    <HitComponent
                    key={hit.id}
                    index={index}
                    hit={hit}
                    query={query} />
                ))}
                
                </SearchSection>
                </SearchContainer>
            )}
            
        </div>}
    </>
      
    )
  }
}

export default AlgoliaSearch
